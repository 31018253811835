import { Form, useRouteLoaderData } from '@remix-run/react'
import { trackEvent } from 'fathom-client'
import { useState } from 'react'
import type { loader as RootLoader } from '~/root'
import * as accountUtils from '~/utils/accounts'
import { Loading } from './Loading'
import { UseBuyOnLoungePair } from './Mark'

export function ToggleAffiliateOffers() {
  const root = useRouteLoaderData<typeof RootLoader>('root')
  if (
    accountUtils.hasRole(root?.account, 'USER') ||
    accountUtils.hasRole(root?.account, 'ADMIN')
  )
    return null
  if (root?.flagsHideAffiliateOffers) return null
  if (!root?.canToggleAffiliateOffers) return null
  const [isLoading, setIsLoading] = useState(false)

  if (root?.hideAffiliateOffers) {
    return (
      <div className="flex gap-1 mb-6 text-stone-500">
        <Form
          method="POST"
          action="/api/v1/sessions"
          className="flex-1"
          onSubmit={() => {
            setIsLoading(true)
          }}
          reloadDocument
        >
          <p className="inline-block py-1 px-2 my-0 bg-stone-50">
            Only showing LoungePair offers
            <span className="hidden mx-1 sm:inline-block">→</span>
          </p>
          <button
            type="submit"
            name="intent"
            value="show"
            className="mt-2 sm:mt-0 ml-2 text-sm font-bold underline"
            onClick={() => trackEvent('Show All Offers Button Clicked')}
          >
            Show all offers
          </button>
        </Form>
        <Loading isLoading={isLoading} invert={false} />
      </div>
    )
  }
  return (
    <div className="gap-1 mb-6 flex text-stone-500">
      <Form
        method="POST"
        action="/api/v1/sessions"
        className="flex-1"
        onSubmit={() => {
          setIsLoading(true)
        }}
        reloadDocument
      >
        <p className="inline-block px-2 py-1 bg-stone-50 text-sm">
          <UseBuyOnLoungePair className="inline-block h-6 mr-2 text-stone-800" />
          Lounges you can book today with LoungePair
          <span className="hidden mx-1 sm:inline-block">→</span>
        </p>
        <button
          type="submit"
          name="intent"
          value="hide"
          className="mt-2 sm:mt-0 text-sm px-2 font-bold text-blue-800 bg-blue-50 underline"
          onClick={() => trackEvent('Hide Affiliate Offers Button Clicked')}
        >
          Show these only
        </button>
      </Form>
      <Loading isLoading={isLoading} invert={false} />
    </div>
  )
}
